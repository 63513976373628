import i18next from "i18next";

import { TabGroup } from "components";
import { UserInterface } from "@types";
import { AuthService } from "core/services";
import { Documents } from "../components/Documents";
import { ChooseSubscription } from "../components/ChooseSubscription";
import { ProfileInformations } from "../components/ProfileInformations";
import { BudgetInsightConnections } from "../components/BudgetInsightConnections";

export function getProfileTabsGroup(
  user: (UserInterface & { hasProCode: boolean }) | null,
  handleAddHash: (hash: string) => void
): TabGroup[] {
  const openExternalLink = (url: string | URL | undefined) => {
    window.open(url, "_blank", "noreferrer");
  };

  const ininitialTabs: TabGroup["tabs"] = [
    {
      id: "informations",
      title: i18next.t("page.profil.tabs.informations"),
      component: <ProfileInformations user={user} />,
      icon: "documents",
      onClick: handleAddHash,
    },
    {
      id: "budget_insight",
      title: i18next.t("title.connections"),
      component: <BudgetInsightConnections />,
      icon: "share",
      onClick: handleAddHash,
    },
  ];

  if (user?.hasProCode == false) {
    ininitialTabs.push({
      id: "abonner-premium",
      title: user?.isPremium
        ? i18next.t("page.profil.tabs.updateSubscription")
        : i18next.t("page.profil.tabs.subscribePremium"),
      component: (
        <div className="rounded-xl py-5 px-6" id="abonner-premium">
          <div className="mb-8">
            <p className="bold w-full text-center text-xl">
              Choisissez la formule la plus adaptée
              <span className="mt-3 block font-normal">
                {" "}
                à votre profil et à vos besoins !
              </span>
            </p>
          </div>
          <ChooseSubscription />
        </div>
      ),
      icon: "wc_logo",
      onClick: handleAddHash,
    });
  }

  const documentsTabs: TabGroup["tabs"] = [];

  if (user?.hasProCode == true) {
    documentsTabs.push({
      id: "documents",
      title: i18next.t("page.profil.tabs.my_documents"),
      component: <Documents />,
      icon: "documents",
      onClick: handleAddHash,
    });
  }

  return [
    {
      tabs: ininitialTabs,
    },
    {
      tabs: documentsTabs,
    },
    {
      tabs: [
        {
          id: "contact",
          title: i18next.t("page.profil.tabs.questions"),
          onClick: () =>
            openExternalLink(
              "https://wealthcome.freshdesk.com/support/tickets/new"
            ),
          icon: "question",
        },
        {
          id: "incident",
          title: i18next.t("page.profil.tabs.reportIncident"),
          onClick: () =>
            openExternalLink(
              "https://wealthcome.freshdesk.com/support/tickets/new"
            ),
          icon: "warning",
        },
      ],
    },
    {
      tabs: [
        {
          id: "twitter",
          title: "Twitter",
          onClick: () => openExternalLink("https://twitter.com/Wealthcome3"),
          icon: "twitter",
        },
        {
          id: "linkedin",
          title: "LinkedIn",
          onClick: () =>
            openExternalLink("https://www.linkedin.com/company/wealthcome/"),
          icon: "linkedin",
        },
        {
          id: "instagram",
          title: "Instagram",
          onClick: () =>
            openExternalLink("https://www.instagram.com/_wealthcome/"),
          icon: "instagram",
        },
        {
          id: "facebook",
          title: "Facebook",
          onClick: () =>
            openExternalLink(
              "https://www.facebook.com/profile.php?id=100076531013609"
            ),
          icon: "facebook",
        },
        {
          id: "share",
          title: "Partager Wealthcome",
          onClick: () =>
            openExternalLink(
              "https://www.facebook.com/profile.php?id=100076531013609"
            ),
          icon: "share",
        },
      ],
    },
    {
      tabs: [
        {
          id: "mentions-legales",
          title: i18next.t("page.profil.tabs.legalNotice"),
          onClick: () =>
            openExternalLink("https://wealthcome.fr/mentions-legales"),
          icon: "balance",
        },
        {
          id: "cgu",
          title: i18next.t("page.profil.tabs.cgu"),
          onClick: () =>
            openExternalLink("https://wealthcome.fr/conditions-generales"),
          icon: "file",
        },
        {
          id: "cgv",
          title: i18next.t("page.profil.tabs.cgv"),
          onClick: () =>
            openExternalLink(
              "https://www.wealthcome.fr/conditions-generales-de-vente"
            ),
          icon: "file",
        },
        {
          id: "confidentialite",
          title: i18next.t("page.profil.tabs.privacyPolicy"),
          onClick: () =>
            openExternalLink(
              "https://wealthcome.fr/politique-de-confidentialite"
            ),
          icon: "privacy",
        },
      ],
    },
    {
      tabs: [
        {
          id: "deconnexion",
          title: i18next.t("page.profil.tabs.logout"),
          onClick: () => AuthService.localLogout(),
          icon: "logout",
        },
      ],
    },
  ];
}
