import { gql } from "graphql-request";
import { useQuery } from "react-query";

import { UserInterface } from "@types";
import { client } from "core/queries/makeGQLQuery";

async function getUser() {
  const query = gql`
    query GetUser {
      getUserSettings {
        id
        name
        email
        firstName
        lastName
        phoneNumber
        birthday
        location
        linkedin
        isPremium
        plan
        referenceInstrument
        layoutSettings
        phoneNumber
        investorProfile
        retirementSimulation
        availableLiquidity
        enableCGPManagment
        loanDetails {
          loanCapacity
          debtRate
        }
        patrimonialsGoals {
          investmentCategories
          investmentHorizon
          investmentType
          yield
        }
        informations
        spending
        annualIncome
        savingsPerYear
        monthlyRepayment

        retirementForm {
          id
          userId
          profession
          best25Year
          dateOfBirth
          endOfActivity
          startOfActivity
          yearsOfInterruption
        }
      }
    }
  `;

  const response = await client.request(query).catch((error) => {
    if (error.response.data) {
      return error.response.data;
    }

    throw error;
  });

  return response.getUserSettings as UserInterface;
}

export function useUser(enabled = true) {
  return useQuery(["user"], {
    queryFn: getUser,
    staleTime: 0,
    enabled,
  });
}
